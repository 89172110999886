<template>
  <div class="home relative text-gray-800">
    <div class="fixed right-0 z-50" style="top: 50%; transform: translateY(-50%);">
      <a href="tel:+6581269366" class="flex items-center justify-center p-2 bg-red-600">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32">
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M9.366 10.682a10.556 10.556 0 0 0 3.952 3.952l.884-1.238a1 1 0 0 1 1.294-.296 11.422 11.422 0 0 0 4.583 1.364 1 1 0 0 1 .921.997v4.462a1 1 0 0 1-.898.995c-.53.055-1.064.082-1.602.082C9.94 21 3 14.06 3 5.5c0-.538.027-1.072.082-1.602A1 1 0 0 1 4.077 3h4.462a1 1 0 0 1 .997.921A11.422 11.422 0 0 0 10.9 8.504a1 1 0 0 1-.296 1.294l-1.238.884zm-2.522-.657l1.9-1.357A13.41 13.41 0 0 1 7.647 5H5.01c-.006.166-.009.333-.009.5C5 12.956 11.044 19 18.5 19c.167 0 .334-.003.5-.01v-2.637a13.41 13.41 0 0 1-3.668-1.097l-1.357 1.9a12.442 12.442 0 0 1-1.588-.75l-.058-.033a12.556 12.556 0 0 1-4.702-4.702l-.033-.058a12.442 12.442 0 0 1-.75-1.588z"
            fill="rgba(255,255,255,1)" />
        </svg>
      </a>
      <a href="https://wa.me/6580306663" target="_blank" class="flex items-center justify-center p-2 bg-green-600">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32">
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M7.253 18.494l.724.423A7.953 7.953 0 0 0 12 20a8 8 0 1 0-8-8c0 1.436.377 2.813 1.084 4.024l.422.724-.653 2.401 2.4-.655zM2.004 22l1.352-4.968A9.954 9.954 0 0 1 2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.954 9.954 0 0 1-5.03-1.355L2.004 22zM8.391 7.308c.134-.01.269-.01.403-.004.054.004.108.01.162.016.159.018.334.115.393.249.298.676.588 1.357.868 2.04.062.152.025.347-.093.537a4.38 4.38 0 0 1-.263.372c-.113.145-.356.411-.356.411s-.099.118-.061.265c.014.056.06.137.102.205l.059.095c.256.427.6.86 1.02 1.268.12.116.237.235.363.346.468.413.998.75 1.57 1l.005.002c.085.037.128.057.252.11.062.026.126.049.191.066a.35.35 0 0 0 .367-.13c.724-.877.79-.934.796-.934v.002a.482.482 0 0 1 .378-.127c.06.004.121.015.177.04.531.243 1.4.622 1.4.622l.582.261c.098.047.187.158.19.265.004.067.01.175-.013.373-.032.259-.11.57-.188.733a1.155 1.155 0 0 1-.21.302 2.378 2.378 0 0 1-.33.288 3.71 3.71 0 0 1-.125.09 5.024 5.024 0 0 1-.383.22 1.99 1.99 0 0 1-.833.23c-.185.01-.37.024-.556.014-.008 0-.568-.087-.568-.087a9.448 9.448 0 0 1-3.84-2.046c-.226-.199-.435-.413-.649-.626-.89-.885-1.562-1.84-1.97-2.742A3.47 3.47 0 0 1 6.9 9.62a2.729 2.729 0 0 1 .564-1.68c.073-.094.142-.192.261-.305.127-.12.207-.184.294-.228a.961.961 0 0 1 .371-.1z"
            fill="rgba(255,255,255,1)" />
        </svg>
      </a>
      <a href="mailto:enquiry@greenemployment.sg" target="_blank"
        class="flex items-center justify-center p-2 bg-blue-600">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32">
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm17 4.238l-7.928 7.1L4 7.216V19h16V7.238zM4.511 5l7.55 6.662L19.502 5H4.511z"
            fill="rgba(255,255,255,1)" />
        </svg>
      </a>
    </div>
    <section class="fixed z-50 w-full bg-white border-b border-gray-400">
      <div class="sm:flex-row flex flex-col items-center justify-between max-w-screen-lg p-4 mx-auto">
        <h1 class="sm:mb-0 sm:mt-1 md:order-1 mb-4"><img src="~@/assets/images/logo.png"></h1>
        <div class="md:order-3 flex">
          <a href="tel:+6580306663" class="block px-4 py-2 text-sm text-white bg-green-500 rounded">Call us at +65 8030
            6663</a>
        </div>
        <div class="md:order-2 md:pt-0 flex justify-center pt-4">
          <a href="#green-story"
            class="text-sm-custom lg:text-sm lg:px-4 lg:mx-2 lg:ml-0 p-2 mx-1 ml-0 bg-green-200 rounded">Green Story</a>
          <a href="#why-green" class="text-sm-custom lg:text-sm lg:px-4 lg:mx-2 p-2 mx-1 bg-green-200 rounded">Why
            Green?</a>
          <a href="#testimonials"
            class="text-sm-custom lg:text-sm lg:px-4 lg:mx-2 p-2 mx-1 bg-green-200 rounded">Testimonials </a>
          <a href="#contact-us"
            class="text-sm-custom lg:text-sm lg:px-4 lg:mx-2 lg:mr-0 p-2 mx-1 mr-0 bg-green-200 rounded">Contact Us</a>
        </div>
      </div>
    </section>
    <section class="md:bg-transparent relative bg-green-200">
      <div class="sm:block absolute z-10 hidden w-full h-full">
        <carousel class="h-full" :per-page="1" :loop="true" :mouse-drag="true" :autoplay="true"
          :pagination-enabled="false">
          <slide class="md:bg-center md:bg-cover bg-banner-02 w-full h-full bg-right">

          </slide>
          <slide class="md:bg-cover bg-banner-01 w-full h-full bg-center">

          </slide>
        </carousel>
      </div>
      <div class="lg:pt-24 lg:pb-20 relative z-40 max-w-screen-xl px-4 pt-16 pb-8 mx-auto">
        <div class="flex flex-wrap items-center -mx-4 overflow-hidden">
          <div class="lg:w-2/3 lg:order-2 w-full px-4">
            <div class="lg:mt-0 lg:text-left mt-24 text-center">
              <div class="bg-opacity-80 lg:p-8 lg:w-10/12 p-2 bg-white">
                <h2 class="lg:pt-0 lg:text-5xl pt-8 mb-2 text-3xl font-semibold leading-tight">Nurturing Love &
                  Compassion</h2>
                <h3 class="lg:mb-8 lg:text-2xl mb-6 font-semibold">Successful & lasting relationship <br
                    class="sm:hidden">that you cherish</h3>
                <ul class="lg:mb-4 list-banner lg:ml-0 mb-8 ml-6 text-left">
                  <li class="lg:text-lg pl-8 mb-4">Instill “Love & Compassion”</li>
                  <li class="lg:text-lg pl-8 mb-4">Socially Responsible</li>
                  <li class="lg:text-lg pl-8">Professional & Sincere</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="sm:hidden w-full px-4" style="height:320px">
            <carousel class="h-full" :per-page="1" :loop="true" :mouse-drag="true" :autoplay="true"
              :autoplay-timeout="3000" :pagination-enabled="false">
              <slide class="bg-banner-02 w-full h-full bg-right bg-cover">

              </slide>
              <slide class="bg-banner-01 w-full h-full bg-right bg-cover">

              </slide>
            </carousel>
          </div>
          <div class="lg:w-1/3 lg:order-1 w-full px-4">
            <div class="lg:px-6 lg:py-10 lg:mt-12 enquiry-form px-4 py-8 bg-white">
              <!-- feedback.activamedia.com.sg script begins here --><iframe allowTransparency="true"
                style="min-height:550px; height:inherit; overflow:auto;" width="100%" id="contactform123"
                name="contactform123" marginwidth="0" marginheight="0" frameborder="0"
                src="https://feedback.activamedia.com.sg/my-contact-form-6046811.html">
                <p>Your browser does not support iframes. The contact form cannot be displayed. Please use another
                  contact method (phone, fax etc)</p>
              </iframe><!-- feedback.activamedia.com.sg script ends here -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="green-story" class="lg:pt-20 lg:pb-16 relative pt-16 pb-12">
      <div class="lg:w-1/2 bg-leaves-up opacity-20 absolute top-0 right-0 z-10 w-full h-full bg-cover"></div>
      <div class="relative z-40 max-w-screen-xl px-4 mx-auto">
        <div class="flex flex-wrap -mx-4 overflow-hidden">
          <div class="lg:w-1/2 w-full px-4">
            <div class="displayBox" data-aos="slide-right" data-aos-duration="1500" data-aos-delay="500">
              <img class="mb-4 rounded" src="~@/assets/images/image-01.jpg">
            </div>
          </div>
          <div class="lg:w-1/2 w-full px-4">
            <h3 class="lg:text-3xl lg:mb-8 text-siteDGreen mb-4 text-2xl font-semibold">The GREEN Story</h3>
            <p class="lg:text-base mb-4 text-sm"><b>GREEN Employment Agency</b> is dedicated and committed to the cause
              of <b>making positive impact to the lives of our candidates, employers and society we operate in.</b></p>
            <p class="lg:text-base mb-4 text-sm">Helmed by <b>professionals with many years of experience</b> in the
              employment agency industry, we relentlessly strive to improve our professionalism and service standard to
              meet the demands of both employers and candidates. We also continuously find ways to support and give back
              to the families and society in Singapore, and the local communities in source countries where we recruit
              from.</p>
            <p class="lg:text-base mb-4 text-sm">Employment service is human centric by nature. Having mutual and
              reciprocal bonds and trusts between the employers and candidates is essential to a successful and lasting
              employment relationship. Hence, we at Green <b>strongly advocate and promote ‘Love & Compassion’</b> in
              the employer-helper relationship, besides competency and profile matching.</p>
            <h4 class="lg:text-base text-sm font-semibold text-green-600">Core Values</h4>
            <p class="lg:text-base mb-8 text-sm"><b>Compassion, Accountability, Passion, Teamwork, Adaptability,
                Integrity, Novelty</b></p>
          </div>
        </div>
      </div>
      <div class="lg:w-2/3 lg:w-1/2 flex flex-wrap w-full mx-auto overflow-hidden">
        <div class="lg:w-1/5 w-1/2 px-4">
          <img class="mb-4 rounded" src="~@/assets/images/logo-01.jpg">
        </div>
        <div class="lg:w-1/5 w-1/2 px-4">
          <img class="mb-4 rounded" src="~@/assets/images/logo-02.jpg">
        </div>
        <div class="lg:w-1/5 w-1/2 px-4">
          <img class="mb-4 rounded" src="~@/assets/images/logo-04.jpg">
        </div>
        <div class="lg:w-1/5 w-1/2 px-4">
          <img class="mb-4 rounded" src="~@/assets/images/logo-03.jpg">
        </div>
        <div class="lg:w-1/5 w-1/2 px-4">
          <img class="mb-4 rounded" src="~@/assets/images/logo-05.jpg">
        </div>
      </div>
    </section>
    <section class="lg:pt-20 lg:pb-16 pt-16 pb-12 bg-green-100">
      <div class="max-w-screen-xl px-4 mx-auto">
        <div class="flex flex-wrap -mx-4 overflow-hidden">
          <div class="lg:w-1/2 lg:order-2 w-full px-4">
            <div class="displayBox" data-aos="slide-left" data-aos-duration="1500">
              <img class="mb-4 rounded" src="~@/assets/images/image-02.jpg">
            </div>
          </div>
          <div class="lg:w-1/2 lg:order-1 w-full px-4">
            <h3 class="lg:text-3xl lg:mb-8 mb-4 text-2xl font-semibold"><span class="text-siteDGreen">GREEN-IES,</span>
              GREEN nannies who serve with hearts</h3>
            <p class="lg:text-base mb-4 text-sm">We name our domestic worker candidates <b>‘GREEN-ies’,</b> which stands
              for <b>‘GREEN nannies’.</b></p>
            <p class="lg:text-base mb-4 text-sm">GREEN-ies come from <b>Indonesia, Myanmar & the Philippines.</b> By
              working with reliable overseas partners, careful screening and selection, training for the first timers,
              we aim to provide quality and qualified candidates to Singapore families based on the needs and
              requirements.</p>
            <p class="lg:text-base mb-4 text-sm">Since this is essentially a service to match between employers and
              domestic helpers, both humans, it’s impossible to have 100% perfect match. Our experience tells us that
              while competence and profile matching is important, what sets an outstanding helper apart in the eyes of
              employers is the intangible one, attitude and character. </p>
            <p class="lg:text-base mb-4 text-sm">Traits that we expect from and that we try to instil in our
              candidates:<br>
              <b>Love & Compassion, Caring, Disciplined, Good attitude, Good tempered (patient), Honest, Hygienic
                (clean), Proactive, Respectful, Responsible.</b>
            </p>
            <p class="lg:text-base mb-2 text-sm">Trusted by our overseas recruitment partners and also the candidates,
              we have a big pool of candidates to cater for Singapore families:</p>
            <ul class="pl-5 mb-4 list-disc">
              <li class="lg:text-base text-sm">Child and infant care</li>
              <li class="lg:text-base text-sm">Elderly care</li>
              <li class="lg:text-base text-sm">Bed-ridden and patient care</li>
              <li class="lg:text-base text-sm">Pet care</li>
              <li class="lg:text-base text-sm">Housework</li>
              <li class="lg:text-base text-sm">Cooking</li>
              <li class="lg:text-base text-sm">English / Cantonese/ Mandarin speaking</li>
              <li class="lg:text-base text-sm">Caregiver / Nursing background</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section id="why-green" class="bg-green lg:pt-20 lg:pb-16 pt-16 pb-12 text-white bg-center bg-cover">
      <div class="max-w-screen-xl mx-auto">
        <h3 class="lg:mb-12 lg:text-3xl px-4 mb-8 text-2xl font-semibold text-center">OK, you found us. <span
            class="text-siteLGreen lg:inline block">So why GREEN?</span></h3>
        <div class="lg:block hidden">
          <carousel class="mb-4" :per-page="3" :mouse-drag="true" :loop="true" :autoplay="true"
            pagination-color="#D1D5DB" pagination-active-color="#00bf6f">
            <slide>
              <div class="px-4">
                <div class="displayBox" data-aos="slide-down" data-aos-duration="1500">
                  <img class="img-fluid mx-auto mb-4 rounded" src="~@/assets/images/image-04.jpg">
                </div>
                <h4 class="lg:text-base mb-2 text-sm font-semibold">Professional team with ‘above & beyond’ service
                  level</h4>
                <ul class="lg:mb-4 pl-4 mb-8 list-disc">
                  <li class="lg:text-sm text-xs">Experienced team with many years of experience in the industry</li>
                  <li class="lg:text-sm text-xs">We offer professional advices and swift solutions for all your
                    employment needs</li>
                  <li class="lg:text-sm text-xs"><b>We aim to set the bar for service standard in the industry, before &
                      after sale</b></li>
                </ul>
              </div>
            </slide>
            <slide>
              <div class="px-4">
                <div class="displayBox" data-aos="slide-down" data-aos-duration="1500" data-aos-delay="500">
                  <img class="img-fluid mx-auto mb-4 rounded" src="~@/assets/images/image-05.jpg">
                </div>
                <h4 class="lg:text-base mb-2 text-sm font-semibold">We serve with heart & sincerity</h4>
                <ul class="lg:mb-4 pl-4 mb-8 list-disc">
                  <li class="lg:text-sm text-xs">At GREEN, happiness and satisfaction is important for our team and for
                    our clients</li>
                  <li class="lg:text-sm text-xs">We seek mutual trust and understanding that will act in your best
                    interest when issues arise</li>
                  <li class="lg:text-sm text-xs"><b>For needy families, please talk to us. We have special assistance
                      package for you</b></li>
                </ul>
              </div>
            </slide>
            <slide>
              <div class="px-4">
                <div class="displayBox" data-aos="slide-down" data-aos-duration="1500" data-aos-delay="1000">
                  <img class="img-fluid mx-auto mb-4 rounded" src="~@/assets/images/image-06.jpg">
                </div>
                <h4 class="lg:text-base mb-2 text-sm font-semibold">Quality and qualified candidates with ‘Love &
                  Compassion’</h4>
                <ul class="lg:mb-4 pl-4 mb-8 list-disc">
                  <li class="lg:text-sm text-xs">Besides competency & profile, we focus significantly on attitude and
                    character</li>
                  <li class="lg:text-sm text-xs">Our candidates are not perfect, so please give them time and space to
                    adapt and improve</li>
                  <li class="lg:text-sm text-xs">Issues are inevitable in a relationship, we understand the issues and
                    will work with you closely to improve/ resolve it</li>
                </ul>
              </div>
            </slide>
            <slide>
              <div class="px-4">
                <div class="displayBox" data-aos="slide-down" data-aos-duration="1500" data-aos-delay="1500">
                  <img class="img-fluid mx-auto mb-4 rounded" src="~@/assets/images/image-07.jpg">
                </div>
                <h4 class="lg:text-base mb-2 text-sm font-semibold">Clear fee structure and refund policy</h4>
                <ul class="lg:mb-4 pl-4 mb-8 list-disc">
                  <li class="lg:text-sm text-xs">We wish to build lasting relationship with you, not just a one-off
                    business transaction</li>
                  <li class="lg:text-sm text-xs">If you think we are not up to the mark, we will try harder</li>
                  <li class="lg:text-sm text-xs">If still doesn’t work out, we have clear refund policy for you as an
                    option</li>
                </ul>
              </div>
            </slide>
            <slide>
              <div class="px-4">
                <div class="displayBox" data-aos="slide-down" data-aos-duration="1500" data-aos-delay="2000">
                  <img class="img-fluid mx-auto mb-4 rounded" src="~@/assets/images/image-08.jpg">
                </div>
                <h4 class="lg:text-base mb-2 text-sm font-semibold">We are optimistic that future can be greener and
                  better</h4>
                <ul class="lg:mb-4 pl-4 mb-8 list-disc">
                  <li class="lg:text-sm text-xs">We continuously find ways to give back to society where we operate in
                  </li>
                  <li class="lg:text-sm text-xs">We take action to support our candidates and their families back home
                  </li>
                  <li class="lg:text-sm text-xs">So, give us a chance to serve you, and serve the future!</li>
                </ul>
              </div>
            </slide>
          </carousel>
        </div>
        <div class="lg:hidden">
          <carousel class="mb-4" :per-page="1" :mouse-drag="true" :loop="true" :autoplay="true"
            pagination-color="#ffffff" pagination-active-color="#00bf6f">
            <slide>
              <div class="px-4">
                <div class="displayBox" data-aos="slide-down" data-aos-duration="1500">
                  <img class="img-fluid mx-auto mb-4 rounded" src="~@/assets/images/image-04.jpg">
                </div>
                <h4 class="lg:text-base mb-2 text-sm font-semibold">Professional team with ‘above & beyond’ service
                  level</h4>
                <ul class="lg:mb-4 pl-4 mb-8 list-disc">
                  <li class="lg:text-sm text-xs">Experienced team with many years of experience in the industry</li>
                  <li class="lg:text-sm text-xs">We offer professional advices and swift solutions for all your
                    employment needs</li>
                  <li class="lg:text-sm text-xs"><b>We aim to set the bar for service standard in the industry, before &
                      after sale</b></li>
                </ul>
              </div>
            </slide>
            <slide>
              <div class="px-4">
                <div class="displayBox" data-aos="slide-down" data-aos-duration="1500">
                  <img class="img-fluid mx-auto mb-4 rounded" src="~@/assets/images/image-05.jpg">
                </div>
                <h4 class="lg:text-base mb-2 text-sm font-semibold">We serve with heart & sincerity</h4>
                <ul class="lg:mb-4 pl-4 mb-8 list-disc">
                  <li class="lg:text-sm text-xs">At GREEN, happiness and satisfaction is important for our team and for
                    our clients</li>
                  <li class="lg:text-sm text-xs">We seek mutual trust and understanding that will act in your best
                    interest when issues arise</li>
                  <li class="lg:text-sm text-xs"><b>For needy families, please talk to us. We have special assistance
                      package for you</b></li>
                </ul>
              </div>
            </slide>
            <slide>
              <div class="px-4">
                <div class="displayBox" data-aos="slide-down" data-aos-duration="1500">
                  <img class="img-fluid mx-auto mb-4 rounded" src="~@/assets/images/image-06.jpg">
                </div>
                <h4 class="lg:text-base mb-2 text-sm font-semibold">Quality and qualified candidates with ‘Love &
                  Compassion’</h4>
                <ul class="lg:mb-4 pl-4 mb-8 list-disc">
                  <li class="lg:text-sm text-xs">Besides competency & profile, we focus significantly on attitude and
                    character</li>
                  <li class="lg:text-sm text-xs">Our candidates are not perfect, so please give them time and space to
                    adapt and improve</li>
                  <li class="lg:text-sm text-xs">Issues are inevitable in a relationship, we understand the issues and
                    will work with you closely to improve/ resolve it</li>
                </ul>
              </div>
            </slide>
            <slide>
              <div class="px-4">
                <div class="displayBox" data-aos="slide-down" data-aos-duration="1500">
                  <img class="img-fluid mx-auto mb-4 rounded" src="~@/assets/images/image-07.jpg">
                </div>
                <h4 class="lg:text-base mb-2 text-sm font-semibold">Clear fee structure and refund policy</h4>
                <ul class="lg:mb-4 pl-4 mb-8 list-disc">
                  <li class="lg:text-sm text-xs">We wish to build lasting relationship with you, not just a one-off
                    business transaction</li>
                  <li class="lg:text-sm text-xs">If you think we are not up to the mark, we will try harder</li>
                  <li class="lg:text-sm text-xs">If still doesn’t work out, we have clear refund policy for you as an
                    option</li>
                </ul>
              </div>
            </slide>
            <slide>
              <div class="px-4">
                <div class="displayBox" data-aos="slide-down" data-aos-duration="1500">
                  <img class="img-fluid mx-auto mb-4 rounded" src="~@/assets/images/image-08.jpg">
                </div>
                <h4 class="lg:text-base mb-2 text-sm font-semibold">We are optimistic that future can be greener and
                  better</h4>
                <ul class="lg:mb-4 pl-4 mb-8 list-disc">
                  <li class="lg:text-sm text-xs">We continuously find ways to give back to society where we operate in
                  </li>
                  <li class="lg:text-sm text-xs">We take action to support our candidates and their families back home
                  </li>
                  <li class="lg:text-sm text-xs">So, give us a chance to serve you, and serve the future!</li>
                </ul>
              </div>
            </slide>
          </carousel>
        </div>
      </div>
    </section>
    <section class="lg:pt-20 lg:pb-16 relative pt-16 pb-12">
      <div class="lg:w-1/2 bg-leaves-down opacity-20 absolute top-0 left-0 z-10 w-full h-full bg-cover"></div>
      <div class="relative z-40 max-w-screen-xl px-4 mx-auto">
        <div class="flex flex-wrap -mx-4 overflow-hidden">
          <div class="lg:w-1/2 w-full px-4">
            <div class="displayBox" data-aos="zoom-in" data-aos-duration="1000">
              <img class="mb-4 rounded" src="~@/assets/images/image-03.jpg">
            </div>
          </div>
          <div class="lg:w-1/2 w-full px-4">
            <h3 class="lg:text-3xl lg:mb-8 mb-4 text-2xl font-semibold">Professional & Reliable One-Stop Employment
              Services</h3>
            <p class="lg:text-base mb-2 text-sm">Full fledge helper hiring services that covers all you need to have a
              smooth hiring process.</p>
            <ul class="pl-5 mb-4 list-disc">
              <li class="lg:text-base text-sm">Indonesia/ Myanmar/ Filipino Migrant Domestic Workers</li>
              <li class="lg:text-base text-sm">Foreign Workers</li>
              <li class="lg:text-base text-sm">Foreign Nurses/ Caregivers</li>
              <li class="lg:text-base text-sm">Candidate Sourcing/ Matching</li>
              <li class="lg:text-base text-sm">New / Transfer Helper</li>
              <li class="lg:text-base text-sm">Direct Hire Application</li>
              <li class="lg:text-base text-sm">Work Permit Application/ Renewal</li>
              <li class="lg:text-base text-sm">The Philippines/ Indonesia Embassy Processing</li>
              <li class="lg:text-base text-sm">Home Leave Application</li>
              <li class="lg:text-base text-sm">Repatriation Service</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section id="testimonials" class="bg-texture lg:pt-20 lg:pb-16 pt-16 pb-12 text-center bg-center bg-cover">
      <div class="max-w-screen-xl px-4 mx-auto">
        <h3 class="lg:text-3xl mb-2 text-2xl font-semibold text-center"><span class="text-green-600">‘Love & Compassion’
            seeds</span> that our team have sown</h3>
        <p class="lg:text-base lg:mb-12 lg:w-2/3 mx-auto mb-8 text-sm">We treasure every customer that entrusts us with
          the employment service. We think that handing over is just the first step of seed being sown, and we will
          continue to nurture it for fruitful long-term relationship.</p>
        <div class="flex flex-wrap -mx-4 overflow-hidden">
          <div class="lg:block hidden w-full px-4">
            <carousel class="mb-4" :per-page="2" :mouse-drag="true" :autoplay="true" pagination-color="#ffffff"
              pagination-active-color="#276749">
              <slide>
                <div class="lg:p-8 p-4 mx-4 text-left bg-white shadow-md">
                  <p class="lg:text-base mb-4 text-sm italic">“First time engaged with Green Employment and had a great
                    experience. Highly recommended as they are the 1st to respond upon sending my enquirers to few other
                    agencies at the same time. Deeply appreciated and “Big Thank You” to the staff who attended me. She
                    is very patient and passionate in her job. Will come back if I need helper again.”</p>
                  <h5 class="lg:text-base text-sm font-medium text-green-600">– Winnie</h5>
                </div>
              </slide>
              <slide>
                <div class="lg:p-8 p-4 mx-4 text-left bg-white shadow-md">
                  <p class="lg:text-base mb-4 text-sm italic">“I engaged Green Employment to help me find a helper for
                    my 91year old mother In September 2022. From the first meeting, I was impressed with the efficiency
                    and professionalism that the staff showed. She listened to my needs and worries patiently. The whole
                    process till the handover of the new maid was very smooth and seamless. She kept us informed
                    throughout the time. I appreciate the responsibility shown by her and the company. Thank you for a
                    most pleasant experience.”</p>
                  <h5 class="lg:text-base text-sm font-medium text-green-600">- Susan</h5>
                </div>
              </slide>
              <slide>
                <div class="lg:p-8 p-4 mx-4 text-left bg-white shadow-md">
                  <p class="lg:text-base mb-4 text-sm italic">“Had a great experience dealing with the staff. She was
                    helpful and patient with all my enquiries, pre and post. Regarding the helper, so far so good. Will
                    definitely recommend Green Employment agency to my friends :)”</p>
                  <h5 class="lg:text-base text-sm font-medium text-green-600">- YS</h5>
                </div>
              </slide>
              <slide>
                <div class="lg:p-8 p-4 mx-4 text-left bg-white shadow-md">
                  <p class="lg:text-base mb-4 text-sm italic">“Excellent customer service from the staff from Green
                    Employment. They have a handful of good profile of helper and patiently allow us to choose the one
                    we want, most importantly understand the needs and preferences of clients from there they filter the
                    helper profile and send to us to choose from. Hassle free they do everything for you. Overall Smooth
                    application and transaction. Highly recommended.”</p>
                  <h5 class="lg:text-base text-sm font-medium text-green-600">- Md Fadli</h5>
                </div>
              </slide>
              <slide>
                <div class="lg:p-8 p-4 mx-4 text-left bg-white shadow-md">
                  <p class="lg:text-base mb-4 text-sm italic">“Professional and reliable agency. The staff matched our
                    family requirements to a handful of profiles and sent them for our review. From inception to
                    on-boarding, she followed up closely and kept us updated. Thank you for the great service!”</p>
                  <h5 class="lg:text-base text-sm font-medium text-green-600">- Janice</h5>
                </div>
              </slide>
              <slide>
                <div class="lg:p-8 p-4 mx-4 text-left bg-white shadow-md">
                  <p class="lg:text-base mb-4 text-sm italic">“The staff is very professional, responsible and patient.
                    Happy to engage with Green Employment.”</p>
                  <h5 class="lg:text-base text-sm font-medium text-green-600">- Yanan</h5>
                </div>
              </slide>
              <slide>
                <div class="lg:p-8 p-4 mx-4 text-left bg-white shadow-md">
                  <p class="lg:text-base mb-4 text-sm italic">“The staff is very helpful and very patient when answering
                    our questions. What a wonderful lady! Sheila has recommended a good helper to us. We were urgent
                    looking for helper to help us before CNY. The staff has done it smoothly without any hiccup and our
                    new helper just arrive on time. Never regret to use Green Employment Agency. Highly recommended!”
                  </p>
                  <h5 class="lg:text-base text-sm font-medium text-green-600">- Collins</h5>
                </div>
              </slide>
            </carousel>
          </div>
          <div class="lg:hidden w-full px-4">
            <carousel class="mb-4" :per-page="1" :mouse-drag="true" :autoplay="true" pagination-color="#ffffff"
              pagination-active-color="#276749">
              <slide>
                <div class="lg:p-8 p-4 mx-4 text-left bg-white shadow-md">
                  <p class="lg:text-base mb-4 text-sm italic">“First time engaged with Green Employment and had a great
                    experience. Highly recommended as they are the 1st to respond upon sending my enquirers to few other
                    agencies at the same time. Deeply appreciated and “Big Thank You” to the staff who attended me. She
                    is very patient and passionate in her job. Will come back if I need helper again.”</p>
                  <h5 class="lg:text-base text-sm font-medium text-green-600">– Winnie</h5>
                </div>
              </slide>
              <slide>
                <div class="lg:p-8 p-4 mx-4 text-left bg-white shadow-md">
                  <p class="lg:text-base mb-4 text-sm italic">“I engaged Green Employment to help me find a helper for
                    my 91year old mother In September 2022. From the first meeting, I was impressed with the efficiency
                    and professionalism that the staff showed. She listened to my needs and worries patiently. The whole
                    process till the handover of the new maid was very smooth and seamless. She kept us informed
                    throughout the time. I appreciate the responsibility shown by her and the company. Thank you for a
                    most pleasant experience.”</p>
                  <h5 class="lg:text-base text-sm font-medium text-green-600">- Susan</h5>
                </div>
              </slide>
              <slide>
                <div class="lg:p-8 p-4 mx-4 text-left bg-white shadow-md">
                  <p class="lg:text-base mb-4 text-sm italic">“Had a great experience dealing with the staff. She was
                    helpful and patient with all my enquiries, pre and post. Regarding the helper, so far so good. Will
                    definitely recommend Green Employment agency to my friends :)”</p>
                  <h5 class="lg:text-base text-sm font-medium text-green-600">- YS</h5>
                </div>
              </slide>
              <slide>
                <div class="lg:p-8 p-4 mx-4 text-left bg-white shadow-md">
                  <p class="lg:text-base mb-4 text-sm italic">“Excellent customer service from the staff from Green
                    Employment. They have a handful of good profile of helper and patiently allow us to choose the one
                    we want, most importantly understand the needs and preferences of clients from there they filter the
                    helper profile and send to us to choose from. Hassle free they do everything for you. Overall Smooth
                    application and transaction. Highly recommended.”</p>
                  <h5 class="lg:text-base text-sm font-medium text-green-600">- Md Fadli</h5>
                </div>
              </slide>
              <slide>
                <div class="lg:p-8 p-4 mx-4 text-left bg-white shadow-md">
                  <p class="lg:text-base mb-4 text-sm italic">“Professional and reliable agency. The staff matched our
                    family requirements to a handful of profiles and sent them for our review. From inception to
                    on-boarding, she followed up closely and kept us updated. Thank you for the great service!”</p>
                  <h5 class="lg:text-base text-sm font-medium text-green-600">- Janice</h5>
                </div>
              </slide>
              <slide>
                <div class="lg:p-8 p-4 mx-4 text-left bg-white shadow-md">
                  <p class="lg:text-base mb-4 text-sm italic">“The staff is very professional, responsible and patient.
                    Happy to engage with Green Employment.”</p>
                  <h5 class="lg:text-base text-sm font-medium text-green-600">- Yanan</h5>
                </div>
              </slide>
              <slide>
                <div class="lg:p-8 p-4 mx-4 text-left bg-white shadow-md">
                  <p class="lg:text-base mb-4 text-sm italic">“The staff is very helpful and very patient when answering
                    our questions. What a wonderful lady! Sheila has recommended a good helper to us. We were urgent
                    looking for helper to help us before CNY. The staff has done it smoothly without any hiccup and our
                    new helper just arrive on time. Never regret to use Green Employment Agency. Highly recommended!”
                  </p>
                  <h5 class="lg:text-base text-sm font-medium text-green-600">- Collins</h5>
                </div>
              </slide>
            </carousel>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-contact lg:pt-20 lg:pb-16 pt-16 pb-12 text-white bg-center bg-cover">
      <div class="max-w-screen-xl px-4 mx-auto">
        <div class="flex flex-wrap justify-center -mx-4 overflow-hidden">
          <div class="lg:w-1/3 w-full px-4">
            <div>
              <img class="mb-4 rounded" src="~@/assets/images/image-09.jpg">
              <h4 class="lg:text-lg mb-1 font-semibold">Start the journey with us</h4>
              <p class="lg:mb-4 lg:text-base mb-8 text-sm">
                Call / WhatsApp / visit us today!
              </p>
              <h4 class="lg:text-lg mb-1 font-semibold">Toa Payoh Branch:</h4>
              <p class="lg:mb-4 lg:text-base mb-8 text-sm">
                Blk 116 Lorong 2 Toa Payoh, #01-152, Singapore 310116<br>
                (Next to Braddell MRT)<br>
                Outlet: <a href="tel:+6565303990">+65 6530 3990</a>
              </p>
              <h4 class="lg:text-lg mb-1 font-semibold">TPY recruitment specialists:</h4>
              <p class="lg:mb-4 lg:text-base mb-8 text-sm">
                Lynn (Liew Li Ting, R22106293): <a href="tel:+6581269366">+65 8126 9366</a><br>
                Chloe (Khaw Lay Kin, R1436074): <a href="tel:+6580306663">+65 8030 6663</a>
              </p>
              <h4 class="lg:text-lg mb-1 font-semibold">Serangoon North Branch:</h4>
              <p class="lg:mb-4 lg:text-base mb-8 text-sm">
                Blk 152 Serangoon North Ave 1, #01-344<br>Singapore 550152<br>
                Outlet: <a href="tel:+6564639189">+65 6463 9189</a>
              </p>
              <h4 class="lg:text-lg mb-1 font-semibold">Serangoon North recruitment specialists:</h4>
              <p class="lg:mb-4 lg:text-base mb-8 text-sm">
                Yong Hong (Pua Yong Hong, R23115332): <br><a href="tel:+6589089900">+65 8908 9900</a><br>
                Caitlyn (Leong Kwang Nyuk, R1223962): <br><a href="tel:+6598632393">+65 9863 2393</a>
              </p>
              <h4 class="lg:text-lg mb-1 font-semibold">Green Employment Pte. Ltd.</h4>
              <p class="lg:mb-4 lg:text-base mb-8 text-sm">
                UEN: 202199666M<br>
                EA Licence No: 21C0720
              </p>
            </div>
          </div>
          <div id="contact-us" class="lg:w-1/3 w-full px-4">
            <div class="lg:px-6 lg:py-10 enquiry-form px-4 py-8 mb-6 bg-white">
              <!-- feedback.activamedia.com.sg script begins here --><iframe allowTransparency="true"
                style="min-height:550px; height:inherit; overflow:auto;" width="100%" id="contactform123"
                name="contactform123" marginwidth="0" marginheight="0" frameborder="0"
                src="https://feedback.activamedia.com.sg/my-contact-form-6046811.html">
                <p>Your browser does not support iframes. The contact form cannot be displayed. Please use another
                  contact method (phone, fax etc)</p>
              </iframe><!-- feedback.activamedia.com.sg script ends here -->
            </div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.726397680778!2d103.8438474147541!3d1.3405002990223216!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da17474fe84dc3%3A0x41468739605a9f1c!2sGreen%20Employment!5e0!3m2!1sen!2sph!4v1643730055710!5m2!1sen!2sph"
              width="100%" height="280" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
          </div>
        </div>
      </div>
    </section>
    <footer class="lg:py-8 px-4 py-4 text-center">
      <h5 class="lg:text-sm text-xs">Website maintained by Activa Media. All Rights Reserved.</h5>
    </footer>
  </div>
</template>
<style>
  .bg-banner-01 {
    background-image: url(~@/assets/images/bg-banner-01.jpg);
  }
  .bg-banner-02 {
    background-image: url(~@/assets/images/bg-banner-02.jpg);
  }
  .list-banner li {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-.997-6l7.07-7.071-1.414-1.414-5.656 5.657-2.829-2.829-1.414 1.414L11.003 16z' fill='rgba(0,191,111,1)'/%3E%3C/svg%3E");
    background-position: left center;
    background-repeat: no-repeat;
  }
  .enquiry-form iframe {
    height: 430px !important;
  }
  .VueCarousel-dot-container {
    margin-top: 0;
  }
  .bg-leaves-up {
    background-image: url(~@/assets/images/texture-leaves-01.png);
  }
  .bg-leaves-down {
    background-image: url(~@/assets/images/texture-leaves-02.png);
  }
  .bg-texture {
    background-image: url(~@/assets/images/bg-texture.jpg);
  }
  .bg-green {
    background-image: url(~@/assets/images/bg-green.jpg);
  }
  .bg-contact {
    background-image: url(~@/assets/images/bg-contact.jpg);
  }
  .VueCarousel-wrapper, .VueCarousel-inner {
    height: 100% !important;
  }
  .text-sm-custom {
    font-size: 10.5px;
  }
  @media (min-width: 640px) {

  }
  @media (min-width: 1024px) {

  }
</style>
<script>
  import { Carousel, Slide } from 'vue-carousel'
  import { CollapseTransition } from 'vue2-transitions'
  export default {
    components: {
      CollapseTransition,
      Carousel,
      Slide
    },
    data() {
      return {
        index: 0,
      }
    }
  }
</script>